.container {
    color: #3E4857;
    font-family: 'Arial';
    font-style: normal;
 
}

.body-color {
    background: #E5E5E5;
    padding: 60px 0 60px 0;
}

h1 .title-text {
    font-size: 36px;
    line-height: 120%;
    font-weight: 700;
}

/* .hero {
    background-image: linear-gradient(90deg,
            #040013 0%,
            #42063f 54.31%,
            #3a047d 99.9%);
} */
