.qr-count {
  margin-left: 8px;
  font-size: 12px;
  background: #9b27af;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  background-color: #eddcf0;
  width: 69px;
  height: 42px;
  //border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9b27af;
}

.password-visibility-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.generator-dropdown{
  margin-left: 2px!important;
  a,
  svg,
  g,
  path,
  use{
    cursor: pointer;
  }
  button {
    box-shadow:none!important;
    &.show {
      background-color: #6A007C !important;
    }
  }
  & .dropdown-menu{
    --bs-dropdown-border-color:#f5f5f5!important;
  }
}

.dropdown-item:hover,.nav-link:hover {
  color: #9B27AF!important;
  background-color: transparent!important;
}
.icon {
  fill: #3E4957;
  width: 16px;
  height: 16px;
}
.dropdown-item:hover .icon,.nav-link:hover .icon{
  fill: #9B27AF;
}
a .up,
button .up {
  display: none;
}
a.show .down,
button.show .down {
  display: none !important;
}
a.show .up,
button.show .up {
  display: block !important;
}