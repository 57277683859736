body {
    font-family: 'Arial';
    font-style: normal;
    background: #D5D5D5;
    background: radial-gradient(161.93% 262.94% at 85.06% 126.06%, #FFFFFF 3.81%, #FBE4FF 63.67%, #CDC8FF 100%);

}

.register-card-title {
    font-weight: 700;
    line-height: 150%;
    color: #3E4957;
}

div.check-card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #8B929A;
}


.register-card-right {
    background: #FEFBFF;

}


.register-card-btn {
    line-height: 150%;
    color: #3E4957;
    border: 1px solid #DDE0E4;
    min-height: 56px;

}

.register-card-right-img {
    right: 0;
    bottom: 0;
}

.register-card-left-img {
    right: 26%;
}

.btn.register-card-left-btn {
    background: #9B27AF;
    font-weight: 700;
    line-height: 150%;
}

.btn.register-card-left-btn:hover {
    background: #9B27AF;
}

.register-card-right-inner-title {
    line-height: 120%;
    color: #3E4957;
}

.register-card-right-inner-text {
    margin-left: 37px;
    line-height: 150%;
    color: #8B929A;
}

.form-floating label {
    color: #8B929A;
}

@media screen and (max-width: 767px) {
    .register-card-right {
        background: #FBF5FE;
    }
}