body {
    font-family: 'Arial';
    font-style: normal;
    background: #D5D5D5;
    background: radial-gradient(161.93% 262.94% at 85.06% 126.06%, #FFFFFF 3.81%, #FBE4FF 63.67%, #CDC8FF 100%);

}

.register-card-title {
    font-weight: 700;
    line-height: 150%;
    color: #3E4957;
}

.register-card-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #8B929A;
}

.register-card-right {
    background: #FEFBFF;

}

@media screen and (max-width: 767px) {
    .register-card-right {
        background: #FBF5FE;
    }
}

.register-btn {
    width: 49%;
}

.register-card-btn {
    line-height: 150%;
    color: #3E4957;
    border: 1px solid #DDE0E4;
    min-height: 56px;
}

.register-card-right-img {
    right: 0;
    bottom: 0;
}

.register-card-left-img {
    right: 26%;
}

.btn.register-card-left-btn {
    background: #9B27AF;
    font-weight: 700;
    line-height: 150%;
}

.btn.register-card-left-btn:hover {
    background: #9B27AF;
}

.register-card-right-inner-title {
    line-height: 120%;
    color: #3E4957;
}

.register-card-right-inner-text {
    margin-left: 37px;
    line-height: 150%;
    color: #8B929A;
}

.form-floating label {
    color: #8B929A;
}

.password-control {
    position: absolute;
    top: 21px;
    right: 26px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(/public//static/pages/login-img/close.png) 0 0 no-repeat;
}

.password-control.view {
    background: url(/public//static/pages/login-img/close.png) 0 0 no-repeat;
}

a.password-text {
    font-weight: 700;
    /* font-size: 14px; */
    line-height: 150%;
    color: #3E4957;
}

a.password-text:hover {
    color: #3E4957;
}

.line {
    padding-top: 2%;
    text-align: center;
    color: #DDE0E4;
}

.line-text {
    background: #fff;
    color: #dde0e4;
    left: 47%;
    padding: 0 10px;
    top: 50%;
}
.lang-bar{
    height: 310px;
    &::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    &::-webkit-scrollbar-track{
        background: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #DDE0E4;
        border-radius: 20px;
    }
}
.lang-code{
    min-width: 25px;
}
.dropdown-item.lang {
    color: #8b929a;
    &:hover{
        background-color: #F5E7F8!important;
        border-radius: 4px;
    }

}
