$primary: #9b27af;
$gray: #8b929a;
$light: #f5f5f5;
$title: #3c4858;
$success: #67ac5b;
$secondary: #796fef;
$info: #dadada;
$dark: #3e4957;
$main-color: #020202;
$tb-06: #ece2ff;
$danger: #f44336;
$danger-light: #FFEFEE;
$dark-purple: #3c057e;
$dropdown-link-hover-color: #9b27af;
$dropdown-link-hover-bg: #ffffff;
$accordion-button-focus-border-color: #ffffff;
$accordion-button-focus-box-shadow: #ffffff;
$font-family-sans-serif: "Arial";
$headings-font-weight: 700;
$booking: #eb5647;
$white: #ffffff;
$main-booking: #0e0d17;
$orange: #f8a40f;
$booking-yellow: #ffe459;
$disable-btn-color: #b6b6b6;
$disable-btn-background: #dfdfdf;
$art-gray: #767676;
$art-light-gray: #fcfaf6;
$ticket-primary: #16b054;
$ticket-primary-light: #e5faed;
$primary-light: #fbf4fc;
$warning: #fffaed;
$warning-dark: #e2b02f;
$success-light: #e9fdea;
$success-dark: #4caf50;
$secondary-light: #ebe9fe;
$light-magenta: #f5e7f8;
$light-gray: #dde0e4;
$gray-light: #FBFBFB;
$main-pos:#5842BE;
$main-pos-text:#1F3354;
$gray-pos-text:#9D9D9D;
$gold: #FED201;
$dark-green: #175743;
$me-page-pink: #CFAFF0;

$blue: #f1f5ff;
$blue-dark: #6f93ef;
$theme-colors: (
  "primary": $primary,
  "gray": $gray,
  "light": $light,
  "title": $title,
  "success": $success,
  "secondary": $secondary,
  "info": $info,
  "dark": $dark,
  "main-color": $main-color,
  "tb-06": $tb-06,
  "danger": $danger,
  "danger-light": $danger-light,
  "dark-purple": $dark-purple,
  "dark-green": $dark-green,
  "booking": $booking,
  "main-booking": $main-booking,
  "white": $white,
  "orange": $orange,
  "booking-yellow": $booking-yellow,
  "art-gray": $art-gray,
  "art-light-gray": $art-light-gray,
  "ticket-primary": $ticket-primary,
  "ticket-primary-light": $ticket-primary-light,
  "primary-light": $primary-light,
  "warning": $warning,
  "warning-dark": $warning-dark,
  "success-light": $success-light,
  "success-dark": $success-dark,
  "secondary-light": $secondary-light,
  "light-magenta": $light-magenta,
  "blue": $blue,
  "blue-dark": $blue-dark,
  "light-gray": $light-gray,
  "gray-light": $gray-light,
        "main-pos": $main-pos,
        "main-pos-text": $main-pos-text,
        "gray-pos-text": $gray-pos-text,
        "violet": #2E0652,

  "gold": $gold,
  "me-page-pink": $me-page-pink,

);

$h1-font-size: 3rem; //(48px)
$h2-font-size: 2.5rem; //(40px)
$h3-font-size: 2rem; //(32px)
$h4-font-size: 1.5rem; //(24px)
$h5-font-size: 1.25rem; //(20px)
$h6-font-size: 1.125rem; //(18px)
$h7-font-size: 0.875rem; //(14px)
$line-height-lg: 1.8;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1040px,
  xl: 1319px,
  xxl: 1320px,
);
$grid-breakpoints: (
        xs: 0,
        sm: 431px,
        md: 745px,
        lg: 1025px,
        xl: 1281px,
        xxl: 1441px
);
$input-border-color: #dde0e4;